import http from "../../utils/Api";
import {
  addUsers,
  getUser,
  getAllUsers,
  updateUsers,
  removeUser,
  getAllUsersPermission,
} from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";




const getStudioUsers = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("Access token is missing");
    }

    const { id } = jwt_decode(token); // Decode token to get user ID
    const queryParams = new URLSearchParams(data).toString(); // Convert data object to query string
    const url = `/api/getGameStudioUsers/${id}?${queryParams}`; // Append query string to URL

    const res = await http.get(url, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });

    if (res && res.data) {
      dispatch({
        type: getAllUsers,
        payload: res.data.users, // Ensure you access the correct data property
      });
    }
  } catch (error) {
    console.error("Error fetching studio users:", error.message || error);
  }
};

const getUsersPermission = () => async (dispatch) => {
  try {
    let { id, role_name } = JSON.parse(localStorage.getItem("userRoles"));
    const token = localStorage.getItem("accessToken");

    const res = await http.get(`/api/getRolePermissions/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });

    if (res) {
      dispatch({
        type: getAllUsersPermission,
        payload: res.result,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

const getSingleuser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const { id } = data; // game ID

    const res = await http.get(`/api/game/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });

    dispatch({
      type: getUser,
      payload: res,
    });
  } catch (error) {
    console.error(error);
  }
};

const addUser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);

    const dat = await http.post(
      `/api/createUserWithRole`,
      { ...data, gameStudioId: id },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );

    if (dat) {
      dispatch({
        type: addUsers,
        payload: dat.result,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const updateUser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const { id } = data;

    const dat = await http.put(
      `/api/updateUserById/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );

    if (dat) {
      dispatch({
        type: updateUsers,
        payload: dat.result,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const deleteUser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);

    const dat = await http.post(
      `/api/deleteUser`,
      {
        userId: data,
        gameStudioId: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );

    dispatch({
      type: removeUser,
      payload: dat?.result,
    });

    toast(dat.message, dat.success);
  } catch (err) {
    console.error(err);
  }
};


const getViralMarketingData = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await http.post(
      "api/getBoostLogs",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    return response;
  } catch (err) {
    console.error("Error in getViralMarketingData:", err);
  }
};

const getAllPaidTiers = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await http.get(
      "api/getAllPaidTiers",
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      }
    );
    return response;
  } catch (err) {
    console.error("Error in getAllPaidTiers:", err);
  }
};


export {
  getSingleuser,
  getStudioUsers,
  addUser,
  updateUser,
  deleteUser,
  getViralMarketingData,
  getAllPaidTiers,
  getUsersPermission,
};
