import React, { useState } from "react";
import { Row, Col, Breadcrumb, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import * as Icon from "react-feather";
import UserList from "./UserList";
import {
  getStudioUsers,
  addUser,
  updateUser,
  deleteUser, // Import the deleteUser action
} from "../service/actions/usersActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import http from "../utils/Api";

const AddUser = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [validated, setValidated] = useState(false);

  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    roleId: "",
  });

  // const roles = [
  //   { id: 1, name: "Admin" },
  //   { id: 2, name: "User" },
  //   { id: 3, name: "Manager" },
  //   // ... other roles
  // ];
  useEffect(() => {
    dispatch(getStudioUsers());
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Access token is missing");
      }
  
      const res = await http.get(`/api/getAllRoles`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
  
      if (res && res.data) {
        setRoles(res.data);
      } else {
        console.error("Failed to fetch roles: Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching roles:", error.message || error);
    }
  };
  
  

  const toggleUserState = (user) => {
    if (user.status === "active") {
      user.status = "suspended";
    } else {
      user.status = "active";
    }
    dispatch(updateUser({ status: user.status, id: user.id }));
  };

  const toggleSideMenu = (active) => {
    setSideMenu(active);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onAddUser = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(addUser(formData));
      setShowModal(false);
    }
    setValidated(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setFormData({
      username: user.username,
      email: user.email,
      password: "",
      first_name: user.first_name,
      last_name: user.last_name,
      roleId: user.role_id,
    });
    setShowModal(true);
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (editingUser) {
        const updatedUserData = {
          id: editingUser.id,
          username: formData.username,
          email: formData.email,
          first_name: formData.first_name,
          last_name: formData.last_name,
          roleId: formData.roleId,
        };
        dispatch(updateUser(updatedUserData)); // Assuming updateUser takes (userId, updatedUserData)
        setShowModal(false);
        setEditingUser(null);
      }
    }
    setValidated(true);
  };

  const handleDeleteUser = (user) => {
    dispatch(deleteUser(user?.id));
  };

  const openModal = () => {
    setEditingUser(null); // Reset editingUser when opening the modal for adding
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingUser(null);
  };

  return (
    <div className="page-wrapper">
      {/* Navigation */}
      <Navigation onClick={toggleSideMenu} />
      {/* End Navigation */}

      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Users</h1>
            <Link to="/home" className="breadcrumb-item">
              Home
            </Link>
            <Breadcrumb.Item active>Users</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="add-user-btn-container">
          <Button variant="primary" onClick={openModal}>
            Add User
          </Button>
        </div>
        {/* End Form */}
        <UserList
          users={users}
          onToggleState={toggleUserState}
          onEditUser={handleEditUser}
          onDeleteUser={handleDeleteUser}
        />

        {/* Add/Edit User Modal */}
        <Modal centered show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{editingUser ? "Edit User" : "Add User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={editingUser ? handleUpdateUser : onAddUser}
            >
              <Row>
                <Col md={editingUser ? 12 : 6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="left-aligned-label">
                      Username
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                {!editingUser && (
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                {!editingUser && (
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col md={editingUser ? 12 : 6}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name="roleId"
                      value={formData.roleId}
                      onChange={handleChange}
                    >
                      <option value="">Select a role</option>
                      {roles?.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.role_name.toUpperCase()}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={editingUser ? handleUpdateUser : onAddUser}
            >
              {editingUser ? "Update User" : "Add User"}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="flex-grow-1"></div>
        <Footer />
      </div>
    </div>
  );
};

export default AddUser;
