import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  CloseButton,
  Dropdown,
  Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PaymentForm from "../../components/Form/paymentForm";
import Loader from "../../components/common/Loader";
import { getAllPaidTiers } from "../../service/actions/usersActions";
import http from "../../utils/Api";
import "./Billing.css";

const Billing = ({ game }) => {
  const gameStudio = useSelector((state) => state.gameStudio);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState({});
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [selectedTierAction, setSelectedTierAction] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [customer, setCustomer] = useState({});
  const getDataHandler = async () => {
    const res = await getAllPaidTiers();
    if (res) {
      setData(res);
    }
  };
  const description = data;
  // const getCustomerProfile = async()=>{
  //   try{
  //     const res = await http.get(
  //       `/api/customer-profile/${gameStudio?.authorizeCustomerId}`
  //     );
  //     console.log(res.data.result,'THE RESPONSE')
  //     if (!!res?.data) {
  //       if (res.data !== null) {
  //         setCustomer(res.data.result);
  //       }
  //     } else {
  //       toast.error(`Error ${res.message}`, {
  //         position: "top-right",
  //       });
  //     }
  //   }
  //   catch(err){
  //     console.log(err)
  //   }
  // }
  const getGameSubscription = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const res = await http.get(
        `/api/studio-subscriptionByGameId/${game.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      );
  
      if (res?.result) {
        if (res.result !== null) {
          setSubscription(res.result);
        }
      } else {
        // Uncomment the toast if you want to display an error message
        // toast.error(`Error ${res.message}`, {
        //   position: "top-right",
        // });
      }
    } catch (error) {
      toast.error("Error fetching subscription data", {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };  
  useEffect(() => {
    getDataHandler();
    getGameSubscription();
    // getCustomerProfile();
  }, []);

  const openUpgradeModal = (tier) => {
    setSelectedTierAction(tier);
    setShowUpgradeModal(true);
  };

  const openCancelModal = (tier) => {
    setSelectedTierAction(tier);
    setShowCancelModal(true);
  };

  const cancelSubscription = async () => {
    try {
      const res = await http.post(
        "/api/cancelSubscription",
        {
          subscriptionId: subscription.subscription_id,
          g_id: game.id,
          gs_id: gameStudio.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("accessToken"),
          },
        }
      );
      
      if (res.success === true) {
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        getGameSubscription();
      } else {
        toast.error(`Error in cancelling subscription: ${res.message}`, {
          position: "top-right",
        });
      }
      setShowCancelModal(false);
    } catch (error) {
      toast.error("An error occurred while cancelling the subscription", {
        position: "top-right",
      });
    }
  };

  const upgradeSubscription = async () => {
    try {
      const res = await http.post(
        "/api/upgradeSubscription",
        {
          subscriptionId: subscription.subscription_id,
          amount: parseInt(selectedTierAction.monthly_fee),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("accessToken"),
          },
        }
      );
      
      if (res.sucess === true) {
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        getGameSubscription();
        setShowUpgradeModal(false);
      } else {
        toast.error(`Error in upgading subscription: ${res.message}`, {
          position: "top-right",
        });
      }
      setShowCancelModal(false);
    } catch (error) {
      toast.error("An error occurred while cancelling the subscription", {
        position: "top-right",
      });
    }
  };

  // const openSubscribeModal = (tier) => {
  //   setSelectedTierAction(tier);
  //   setShowSubscribeModal(true);
  // };

  const openPaymentForm = (tier) => {
    setSelectedTierAction(tier);
    setShowPaymentForm(true);
  };

  // Function to close the payment form
  const closePaymentForm = () => {
    setSelectedTierAction(null);
    setShowPaymentForm(false);
  };

  const closeModals = () => {
    setShowUpgradeModal(false);
    setShowCancelModal(false);
    setShowSubscribeModal(false);
    setSelectedTierAction(null);
  };

  return (
    <>
      <Tabs
        defaultActiveKey="Manage Billing"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {/* <Tab eventKey="Invoice" title="Invoice">
          Tab content for Home
        </Tab> */}
        <Tab
          eventKey="Manage Billing"
          title="Manage Billing"
          className="billing-tab"
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            data?.paidTiers?.slice(1).map((items, i) => {
              const hasSubscription = subscription?.subscription?.amount;

              const descriptionList = items?.description.split(/,\s*|\n/);
             return (
                <Accordion style={{ width: "100%" }} key={i}>
                  <Accordion.Item>
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          gap: "5rem",
                        }}
                      >
                        <h3>{items.tier_name}:</h3>
                        <h3>${items.monthly_fee}</h3>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mainBillingParent" key={items.tier_name}>
                        {/* <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check type={type} id={`default-${type}`} />
                            </div>
                          ))}
                        </Form> */}
                        <p style={{ textAlign: "left" }}>
                          {descriptionList.map((value, key) => {
                            return (
                              <div
                                style={{
                                  fontSize: "16px",
                                  margin: "5px",
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                <ul>
                                  <li>{value}</li>
                                </ul>
                              </div>
                            );
                          })}
                          {/* {descriptionList.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))} */}
                        </p>
                        <Dropdown as={ButtonGroup}>
                          <Button
                            variant="primary"
                            onClick={() => {
                              if (!subscription || !subscription.subscription) {
                                openPaymentForm(items);
                              } else if (
                                hasSubscription &&
                                hasSubscription === parseInt(items?.monthly_fee)
                              ) {
                                openCancelModal(items);
                              } else {
                                openUpgradeModal(items);
                              }
                            }}
                          >
                            {!subscription || !subscription.subscription
                              ? "Subscribe"
                              : hasSubscription &&
                                hasSubscription === parseInt(items?.monthly_fee)
                              ? "Cancel"
                              : "Upgrade"}
                          </Button>

                          <Dropdown.Toggle
                            size="lg"
                            split
                            variant="primary"
                            id="dropdown-split-basic"
                          />
                        </Dropdown>
                        {/* <CloseButton
                          className="btn-close-red"
                          variant="custom"
                        /> */}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })
          )}
        </Tab>
      </Tabs>

      {/* Upgrade Modal */}
      <Modal show={showUpgradeModal} onHide={closeModals}>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to upgrade your subscription?
          {/* Add more details and actions as needed */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModals}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              upgradeSubscription();
            }}
          >
            Confirm Upgrade
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Modal */}
      <Modal show={showCancelModal} onHide={closeModals}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel your subscription?
          {/* Add more details and actions as needed */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModals}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              cancelSubscription();
            }}
          >
            Confirm Cancellation
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Subscribe Modal */}

      {/* {payment modal} */}
      <PaymentForm
        showModal={showPaymentForm}
        closeModal={closePaymentForm}
        selectedTier={selectedTierAction}
        game={game}
        gameStudio={gameStudio}
        getGameSubscription={getGameSubscription}
      />
    </>
  );
};

export default Billing;
