import http from "../../utils/Api";
import { add, get, getAll, update, remove } from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";

// Get Studio Games
const getStudioGames = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const gameStudioId = JSON.parse(localStorage.getItem("gameStudioId"));
    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
    let { id } = jwt_decode(token);
    let studioId;
    if (userRoles.id === 1) {
      studioId = id;
    } else {
      studioId = gameStudioId;
    }
    const res = await http.get(
      `/api/game/getGameStudiosGames/${studioId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token, 
        },
        params: data, // Pass data as query parameters
      }
    );

    if (res) {
      dispatch({
        type: getAll,
        payload: res.result,
      });
    }
  } catch (error) {
  }
};

// Get Single Game
const getSingleGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const { id } = data; //game ID
    const res = await http.get(
      `/api/game/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token, 
        },
        params: data, // Pass data as query parameters
      }
    );
   dispatch({
      type: get,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};

// Add Game
const addGames = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const dat = await http.post(
      `/api/game/addGame/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token, 
        },
      }
    );
    if (dat.result) {
      dispatch({
        type: add,
        payload: dat.result,
      });
    }
    // Link the game from the mobile backend
  } catch (err) {
    console.log(err);
  }
};

// Update Game
const updateGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const { id } = data;
    const dat = await http.put(
      `/api/game/updateGame/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token, 
        },
      }
    );
    dispatch({
      type: update,
      payload: dat,
    });
  } catch (err) {
    console.log(err);
  }
};

// Delete Game
const deleteGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const { id, gs_id } = data; //game ID
    const dat = await http.post(
      `/api/game/unlinkStudioGame/`,
      { gameId: id, studioId: gs_id },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token, 
        },
      }
    );
    dispatch({
      type: remove,
      payload: dat.result,
    });
    // Change the tier of the game if it's elevated
  } catch (err) {
    console.log(err);
  }
};

export { getStudioGames, getSingleGame, addGames, updateGame, deleteGame };
